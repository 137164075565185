import React from "react";
import { Switch, Route } from "react-router-dom";
import PrivateRoute from "./components/privateRoute/privateRoute";
import "./assets/styles/global.scss";
import "react-loading-skeleton/dist/skeleton.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import * as Icons from "@fortawesome/free-solid-svg-icons";

import AllContexts from "./components/contexts/allContexts";

import Header from "./components/header/header";
import Designer from "./components/designer/designer";
import Settings from "./components/settings/settings";
import Profile from "./components/profile/profile";
import Login from "./components/authFlow/login/login";
import Signup from "./components/authFlow/signup/signup";
import Intro from "./components/authFlow/intro/intro";
import Pricing from "./components/authFlow/pricing/pricing";
import SubscriptionConfirm from "./components/authFlow/subscriptionConfirm/subscriptionConfirm";
import UmicardViewer from "./components/umicardViewer/umicardViewer";
import Notifications from "./components/notification/notification";
import PrivacyPolicy from "./components/common/tocFooter/pages/privacy/privacy";
import TermsAndConditions from "./components/common/tocFooter/pages/terms/terms";
import CookiePolicy from "./components/common/tocFooter/pages/cookie/cookie";

// Landing pages
import LandingPage from "./components/pages/landing";
import YoutubeLanding from "./components/pages/youtube";
import PricingLanding from "./components/pages/pricing";

const iconList = Object.keys(Icons)
  .filter((key) => key !== "fas" && key !== "prefix")
  .map((icon) => Icons[icon]);

library.add(...iconList);

// const a = [];
// iconList.forEach(({ iconName }) => {
//   if (!a.includes(iconName)) a.push(iconName);
// });
// console.log(a);

const App = () => {
  return (
    <AllContexts>
      <div className="container">
        <Switch>
          <PrivateRoute path="/designer" exact>
            <Header />
            <Designer />
          </PrivateRoute>
          <PrivateRoute path="/settings" exact>
            <Header />
            <Settings />
          </PrivateRoute>
          <PrivateRoute path="/intro" exact>
            <Intro />
          </PrivateRoute>
          <PrivateRoute path="/pricing" exact>
            <Pricing />
          </PrivateRoute>
          <PrivateRoute path="/profile" exact>
            <Header />
            <Profile />
          </PrivateRoute>
          <PrivateRoute path="/profile/:tab" exact>
            <Header />
            <Profile />
          </PrivateRoute>
          <Route path="/login" exact component={Login} />
          <Route path="/login/:magicToken" exact component={Login} />
          <Route path="/signup" exact component={Signup} />
          <Route
            path="/confirm_subscription"
            exact
            component={SubscriptionConfirm}
          />
          <Route
            path="/Terms-and-Conditions"
            exact
            component={TermsAndConditions}
          />
          <Route path="/Privacy-Policy" exact component={PrivacyPolicy} />
          <Route path="/Cookie-Policy" exact component={CookiePolicy} />

          <Route path="/youtube" exact component={YoutubeLanding} />
          <Route path="/umicard-pricing" exact component={PricingLanding} />
          <Route path="/:username" component={UmicardViewer} />
          <Route path="/" exact component={LandingPage} />
        </Switch>
        <Notifications />
      </div>
    </AllContexts>
  );
};

export default App;
