import React, { useState } from "react";
import Zendesk from "react-zendesk";
import { Link } from "react-router-dom";

import NavLinkRender from "./navLink/navLink.jsx";
import UserDetailsDropDown from "./userDetailsDropDown/userDetailsDropDown.jsx";

import styles from "./header.module.scss";
import brand_logo from "../../assets/global/umicard_logo2.png";
import design_svg from "../../assets/header/icon_design.svg";
import settings_svg from "../../assets/header/icon_settings.svg";
import analytics_svg from "../../assets/header/icon_analytics.svg";
import ambassador_svg from "../../assets/header/icon_ambassador.svg";
import help_svg from "../../assets/header/icon_help.svg";
import request_svg from "../../assets/header/icon_request.svg";
import billing_svg from "../../assets/header/dropdownMenu/billing.svg";
import profile_svg from "../../assets/header/dropdownMenu/profile.svg";

const supportURL = "https://support.umicard.com";
const featureRequest = "https://support.umicard.com";
const ZENDESK_KEY = "69c9cf7c-600a-404d-a13b-7ca179e8c36f";

const Header = () => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [customerPortalURL, setCustomerPortalURL] = useState("");

  return (
    <React.Fragment>
      <nav className={styles.header}>
        <div className={`${styles.header_left} vertical_align_center`}>
          <div className={styles.logo_container}>
            <Link to="/">
              <img
                src={brand_logo}
                alt="UmiCard"
                className={styles.brand_logo}
              />
            </Link>
          </div>
          <div className={styles.nav_links}>
            <NavLinkRender
              src={design_svg}
              styles={styles}
              text={"Design"}
              destination={"/designer"}
            />
            {/* <NavLinkRender
              src={analytics_svg}
              styles={styles}
              text={"Analytics"}
              destination={"/analytics"}
            /> */}
            <NavLinkRender
              src={settings_svg}
              styles={styles}
              text={"Settings"}
              destination={"/settings"}
            />
            {/* <NavLinkRender
              src={ambassador_svg}
              styles={styles}
              text={"Ambassador Program"}
              destination={"/ambassador"}
            /> */}
            <NavLinkRender
              src={help_svg}
              styles={styles}
              text={"Help"}
              destination={supportURL}
              fullURL={true}
            />
            <NavLinkRender
              src={request_svg}
              styles={styles}
              text={"Feature Request"}
              destination={featureRequest}
              fullURL={true}
            />
          </div>
        </div>
        <div className={`${styles.header_right}`}>
          <UserDetailsDropDown
            styles={styles}
            customPortalSetMobile={setCustomerPortalURL}
          />
        </div>
        <button
          className={styles.hamburger_icon}
          onClick={() => setMobileNavOpen(!mobileNavOpen)}
        >
          <span className={styles.line}></span>
        </button>
      </nav>
      <div
        className={`${styles.mobile_nav} ${mobileNavOpen ? styles.open : ""}`}
      >
        <button
          className={`${styles.hamburger_icon} ${styles.closing}`}
          onClick={() => setMobileNavOpen(!mobileNavOpen)}
        >
          <span className={styles.line}></span>
        </button>
        <div className={styles.mobile_nav_links}>
          <div className={styles.nav_links}>
            <NavLinkRender
              src={design_svg}
              styles={styles}
              text={"Design"}
              destination={"/designer"}
            />
            {/* <NavLinkRender
              src={analytics_svg}
              styles={styles}
              text={"Analytics"}
              destination={"/analytics"}
            /> */}
            <NavLinkRender
              src={profile_svg}
              styles={styles}
              text={"Profile"}
              destination={"/profile"}
            />
            <NavLinkRender
              src={settings_svg}
              styles={styles}
              text={"Settings"}
              destination={"/settings"}
            />
            {/* <NavLinkRender
              src={ambassador_svg}
              styles={styles}
              text={"Ambassador Program"}
              destination={"/ambassador"}
            /> */}
            <NavLinkRender
              src={help_svg}
              styles={styles}
              text={"Help"}
              destination={supportURL}
              fullURL={true}
            />
            <NavLinkRender
              src={request_svg}
              styles={styles}
              text={"Feature Request"}
              destination={featureRequest}
              fullURL={true}
            />
            {customerPortalURL && (
              <NavLinkRender
                src={billing_svg}
                styles={styles}
                text={"Billing"}
                destination={customerPortalURL}
                fullURL={true}
              />
            )}
          </div>
        </div>
        <Zendesk defer zendeskKey={ZENDESK_KEY} />;
      </div>
    </React.Fragment>
  );
};

export default Header;
