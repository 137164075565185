import React, { useState, useEffect } from "react";
import { ReactSVG } from "react-svg";

import styles from "./sectionTopBar.module.scss";

import cross_svg from "../../../assets/global/icon_cross.svg";
import help_svg from "../../../assets/sections/help.svg";

const SectionTopBar = ({
  title,
  id,
  updateCallback = null,
  clearCallback = null,
  updateData = null,
  extraUpdates = [],
}) => {
  const [showFloatingButton, setShowFloatingButton] = useState(true);

  // console.log(showFloatingButton);

  // useEffect(() => {
  //   window.onscroll = () => {
  //     if (window.scrollY > 70) setShowFloatingButton(true);
  //     else setShowFloatingButton(false);
  //   };
  // }, []);

  return (
    <div className={`${styles.top_bar} vertical_align_center`}>
      <h3 className={styles.heading}>
        {title}{" "}
        <button className={styles.help}>
          <ReactSVG src={help_svg} wrapper={"span"} />
        </button>
      </h3>
      <span className={styles.action_btns}>
        <button
          className={`${styles.clear_all} ${styles.update_btn} vertical_align_center`}
          id={id}
          onClick={() => {
            if (updateCallback && updateData) updateCallback(updateData);
            if (extraUpdates.length > 0) {
              console.log(extraUpdates);
              extraUpdates.forEach(({ extraCallback, extraData }) => {
                extraCallback(extraData);
              });
            }
          }}
        >
          <ReactSVG src={cross_svg} wrapper={"span"} /> Update
        </button>
        <button
          className={`${styles.clear_all} vertical_align_center`}
          id={id}
          onClick={() => {
            clearCallback && clearCallback();
            setShowFloatingButton(!showFloatingButton);
          }}
        >
          <ReactSVG src={cross_svg} wrapper={"span"} /> Clear
        </button>
      </span>
      {/* Floating save button */}
      {showFloatingButton && (
        <button
          className={`${styles.floating_save_button} ${
            showFloatingButton && styles.show
          } vertical_align_center`}
          id={id}
          onClick={() => {
            if (updateCallback && updateData) updateCallback(updateData);
            if (extraUpdates.length > 0) {
              console.log(extraUpdates);
              extraUpdates.forEach(({ extraCallback, extraData }) => {
                extraCallback(extraData);
              });
            }
          }}
        >
          <ReactSVG src={cross_svg} wrapper={"span"} /> Update
        </button>
      )}
    </div>
  );
};

export default SectionTopBar;
