import React, { useState, useEffect } from "react";

import styles from "./selectInputField.module.scss";

const SelectInputField = ({
  inputFieldId,
  options,
  showLabel = true,
  label = "",
  defaultInputValue = "",
  valuesObjectType = false,
  callback = null,
  context = null,
  updateContext = null,
}) => {
  const [selectedValue, setSelectedValue] = useState(defaultInputValue);

  useEffect(() => {
    setSelectedValue(defaultInputValue);
  }, [defaultInputValue]);

  return (
    <div className={styles.select_container}>
      {showLabel && <label htmlFor={inputFieldId}>{label}</label>}
      {valuesObjectType && (
        <select
          name={inputFieldId}
          id={inputFieldId}
          value={selectedValue}
          onChange={(e) => {
            if (callback) callback(e.target.value, context, updateContext);
            setSelectedValue(e.target.value);
          }}
        >
          {options.map(({ value, text }) => {
            return (
              <option key={`${inputFieldId}-${value}`} value={value}>
                {text}
              </option>
            );
          })}
        </select>
      )}
      {!valuesObjectType && (
        <select
          name={inputFieldId}
          id={inputFieldId}
          value={selectedValue}
          onChange={(e) => {
            if (callback) callback(e.target.value, context, updateContext);
            setSelectedValue(e.target.value);
          }}
        >
          {options.map((option) => {
            return (
              <option
                key={`${inputFieldId}-${option}`}
                value={option.toLowerCase()}
              >
                {option}
              </option>
            );
          })}
        </select>
      )}
    </div>
  );
};

export default SelectInputField;
